import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect } from 'react'
import HelpButton from 'src/components/ui/HelpButton/HelpButton'
import Icon from 'src/components/ui/Icon'
import { useLinxNotFoundView } from 'src/sdk/linx/hooks/useLinxNotFoundView'

import '../styles/pages/404.scss'

function Page() {
  const { sendNotFoundViewEvent } = useLinxNotFoundView()

  useEffect(() => {
    sendNotFoundViewEvent()
  }, [sendNotFoundViewEvent])

  return (
    <>
      <GatsbySeo noindex nofollow />
      <div className="container-error h-screen w-full overflow-hidden bg-cover bg-center relative">
        <div className="container-blue right-48 w-full h-full absolute max-w-[490px]" />
        <div className="blue-bar">
          <Icon
            name="BlueBar"
            height={56}
            className="absolute w-full bottom-64 h-14"
            fillOpacity="0.7"
          />
        </div>
        <div className="mobile-container-blue w-full h-full max-h-[256px] absolute bottom-0" />

        <div className="mobile-error-404 text-center not-italic flex flex-col items-center absolute right-72 top-56 justify-center">
          <h1 className="text-white text-6xl font-semibold m-0">OPS!</h1>
          <p className="text-white my-4 text-xs">(Erro 404)</p>
          <h2 className="text-white text-base">
            No momento esta página está indisponível.
          </h2>
          <button className="flex items-center text-center font-extrabold gap-5 text-sm bg-yellow py-2.5 px-12 uppercase mt-12">
            <a href="/">Ir para o site</a>
            <Icon name="ChevronForward" height={15} width={15} />
          </button>
        </div>
        <HelpButton />
      </div>
    </>
  )
}

export default Page
